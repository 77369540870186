import { toast } from "react-hot-toast";
import * as types from "./ActionType";
import instance from "../BaseUrl/BaseUrl";
import instanceImage from "../BaseUrl/BaseUrlImage";
import { handleErrors } from "../util";

// SIGNIN
const signInSuccess = (token) => ({
  type: types.SIGN_IN,
  payload: token,
});

// GET ALL Users
const getUsersSuccess = (users) => ({
  type: types.GET_USERS,
  payload: users,
});
// Update user and listner
const updateUserSuccess = (usersId, updateduser) => ({
  type: types.UPDATE_USER,
  payload: { usersId, updateduser },
});

// GET ALL plan
const getplanSuccess = (plan) => ({
  type: types.GET_ALL_PLAN,
  payload: plan,
});

// Add plan
const addplanSuccess = (plan) => {
  return {
    type: types.SET_PLAN,
    payload: plan,
  };
};
// Update plan
const updateplanSuccess = (planId, updatedplan) => ({
  type: types.UPDATE_PLAN,
  payload: { planId, updatedplan },
});

// GET ALL plan
const getLanguageSuccess = (language) => ({
  type: types.GET_ALL_LANGUAGE,
  payload: language,
});

// Add plan
const addLanguageSuccess = (language) => {
  return {
    type: types.SET_LANGUAGE,
    payload: language,
  };
};
// Update plan
const updateLanguageSuccess = (languageId, updatedLanguage) => ({
  type: types.UPDATE_LANGUAGE,
  payload: { languageId, updatedLanguage },
});
// GET ALL countries
const getCountriesSuccess = (countries) => ({
  type: types.GET_ALL_COUNTRIES,
  payload: countries,
});

// Add countries
const addCountriesSuccess = (countries) => {
  return {
    type: types.SET_COUNTRIES,
    payload: countries,
  };
};
//Update countries
const UpdateCountries = () => ({
  type: types.UPDATE_COUNTRIE,
});

//Delete countries
const deleteCountrieseSuccess = (countryId) => ({
  type: types.DELETE_COUNTRY,
  payload: { countryId },
});

// GET ALL categories
const getCategoriesSuccess = (categories) => ({
  type: types.GET_ALL_CATEGORY,
  payload: categories,
});
// Add categories
const addCategoriesSuccess = (categories) => {
  return {
    type: types.SET_CATEGORY,
    payload: categories,
  };
};
// Update categories
const updateCategoriesSuccess = (categoriId, updatedCategori) => ({
  type: types.UPDATE_CATEGORY,
  payload: { categoriId, updatedCategori },
});

// GET ALL categories
const getLabelsSuccess = (labels) => ({
  type: types.GET_ALL_LABELS,
  payload: labels,
});

// Add labels
const addLabelsSuccess = (labels) => {
  return {
    type: types.SET_LABEL,
    payload: labels,
  };
};

// Update labels
const updateLabelsSuccess = (labelId, updatedLabels) => ({
  type: types.UPDATE_LABEL,
  payload: { labelId, updatedLabels },
});

// Update countries
const updateCountriesSuccess = (countriesId, updatedCountriesi) => ({
  type: types.UPDATE_COUNTRIE,
  payload: { countriesId, updatedCountriesi },
});
// GET ALL commission
const getCommissionSuccess = (commission) => ({
  type: types.GET_COMMISSION,
  payload: commission,
});

// Add commission
const addCommissionsSuccess = (commission) => {
  return {
    type: types.SET_COMMISSION,
    payload: commission,
  };
};

// GET ALL payout
const getPayoutSuccess = (payout) => ({
  type: types.GET_ALL_PAYOUT,
  payload: payout,
});

// GET ALL dashboardData
const getDashboardDataSuccess = (dashboardData) => ({
  type: types.GET_ALL_DASHOAED_DATA,
  payload: dashboardData,
});

// GET ALL feedback
const getFeedbackSuccess = (feedback) => ({
  type: types.GET_ALL_FEEDBACK,
  payload: feedback,
});

// Add payout
const addPayoutSuccess = (payout) => {
  return {
    type: types.SET_PAYOUT,
    payload: payout,
  };
};

// GET ALL LISTENER_BALANACE
const getListerBalanceSuccess = (listerBalance) => ({
  type: types.GET_ALL_LISTENER_BALANACE,
  payload: listerBalance,
});

// GET ALL NOTIFICATIONS
const getNotificationSuccess = (notifications) => ({
  type: types.GET_ALL_NOTIFICATIONS,
  payload: notifications,
});

// Add NOTIFICATIONS
const addNotificationsSuccess = (notifications) => {
  return {
    type: types.SET_NOTIFICATIONS,
    payload: notifications,
  };
};

const getBannerSuccess = (banner) => ({
  type: types.GET_ALL_BANNER,
  payload: banner,
});

// Add BANNER
const addBannersSuccess = (banner) => {
  return {
    type: types.SET_BANNER,
    payload: banner,
  };
};

// Update BANNER
const updateBannerSuccess = (bannerId, updatedBanner) => ({
  type: types.UPDATE_BANNER,
  payload: { bannerId, updatedBanner },
});

//Delete BANNER
const deleteBannerSuccess = (bannerId) => ({
  type: types.DELETE_BANNER,
  payload: { bannerId },
});


//  BOOKINGS
const getBookingSuccess = (booking) => ({
  type: types.GET_ALL_BOOKING,
  payload: booking,
});

//  Quick BOOKINGS
const getQuickBookingSuccess = (quickbooking) => ({
  type: types.GET_ALL_QUICK_BOOKING,
  payload: quickbooking,
});

/* 

##################################### 
  API CALL
##################################### 
*/

// SignIn -API
export const SignInUser = (email, password, navigate) => {
  return async function (dispatch) {
    try {
      const result = await instance.post("/user/admin-signin", {
        email,
        password,
      });
      console.log(result.data);
      if (result?.status === 200) {
        if (result?.data?.status) {
          dispatch(signInSuccess(result?.data?.data));
          localStorage.setItem("user",JSON.stringify({ Token: result.data.token,role: "admin"})
          );
          navigate("/");
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};
/* Update one categories*/
export const updateUser = (data) => {
  const {
    _id,
    bio,
    gender,
    name,
    username,
    email,
    mobile,
    dob,
    dialCode,
    language_speak_fluently,
    country,
    inhouse_listener,
    status,
  } = data;

  return async function (dispatch) {
    try {
      const result = await instance.post(`user//update-user-status`, {
        userId: _id,
        status,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(updateUserSuccess(_id, data));
          toast.success(result?.data?.message);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      console.log(err);
      handleErrors(err);
    }
  };
};

/* Update one categories*/
export const updateLister = (data) => {
  const {
    _id,
    bio,
    gender,
    name,
    username,
    email,
    mobile,
    dob,
    dialCode,
    status,
  } = data;

  return async function (dispatch) {
    try {
      const result = await instance.post(`/listener/updateUser`, {
        status,
        userId: _id,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(updateUserSuccess(_id, data));
          toast.success(result?.data?.message);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

// this  All API for Users
/* Get All USers*/
export const getUsers = () => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/user/getAllUser`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getUsersSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All Language*/
export const getlanguage = () => {
  return async (dispatch) => {
    try {
      const result = await instance.get(`/languages/getAllLanguage`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getLanguageSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All Language*/
export const updateLanguageOrder = (updatedDataIdsOnly) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/languages/dragDropLanguage`, {
        language: updatedDataIdsOnly,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getLanguageSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Add one Language*/
export const SetLanguage = (
  languageName,
  languageCode,
  settogglemenu,
  togglemenu
) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/languages`, {
        languageName,
        languageCode,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(addLanguageSuccess(result.data.data));
          settogglemenu(!togglemenu);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All countries*/
export const getcountries = () => {
  return async (dispatch) => {
    try {
      const result = await instance.get(`/countries/getAllCountryForAdmin`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getCountriesSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All countries*/
export const updateCountriesOrder = (updatedDataIdsOnly) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/countries/dragDropCountry`, {
        country: updatedDataIdsOnly,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getCountriesSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Add one countries*/
export const Setcountries = (
  countryName,
  countryCode,
  currencyCode,
  phoneCode,
  settogglemenu,
  togglemenu
) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/countries/addCountry`, {
        countryName,
        countryCode,
        currencyCode,
        phoneCode,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(addCountriesSuccess(result.data.data));
          settogglemenu(!togglemenu);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Delete one countries*/
export const Setdelete = (id, settogglemenu, togglemenu) => {
  return async (dispatch) => {
    try {
      const result = await instance.delete(`/countries/${id}`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(deleteCountrieseSuccess(id));
          settogglemenu(!togglemenu);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      toast.error(err.response?.data.message);
    }
  };
};

/* Delete one countries*/
export const payOutDelete = (id, settogglemenu, togglemenu) => {
  return async (dispatch) => {
    try {
      const result = await instance.delete(`/payout/deletePayout/${id}`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(deleteCountrieseSuccess(id));
          settogglemenu(!togglemenu);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      toast.error(err.response?.data.message);
    }
  };
};

/* Get All categories*/
export const getCategories = () => {
  return async (dispatch) => {
    try {
      const result = await instance.get(`/categories`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getCategoriesSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All categories*/
export const updateCategoriesOrder = (updatedDataIdsOnly) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/categories/dragDropCategory`, {
        category: updatedDataIdsOnly,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getCategoriesSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Add one categories*/
export const SetCategories = (name, image, settogglemenu, togglemenu) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/categories`, {
        name,
        image,
      });

      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(addCategoriesSuccess(result.data.data));
          toast.success(result?.data?.message);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Update one categories*/
export const updateCategories = (data) => {
  const { _id, image, status, name, isOpen, setisOpen } = data;
  return async function (dispatch) {
    try {
      const result = await instance.post(`/categories/updateCategory/${_id}`, {
        name,
        image,
        status,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(updateCategoriesSuccess(_id, result?.data.data));
          toast.success(result?.data?.message);
          setisOpen(!isOpen);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      console.log(err);
      handleErrors(err);
    }
  };
};

/* Get All labels*/
export const getLabels = () => {
  return async (dispatch) => {
    try {
      const result = await instance.get(`/labels`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getLabelsSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Add one Labels*/
export const SetLabels = (
  code,
  title,
  en,
  status,
  settogglemenu,
  togglemenu
) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/labels`, {
        code,
        title,
        en,
        status,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(addLabelsSuccess(result.data.data));
          settogglemenu(!togglemenu);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Update one Labels*/
export const updateLabel = (data) => {
  const { _id, code, title, value, isOpen, setisOpen } = data;
  return async function (dispatch) {
    try {
      const result = await instance.post(`/labels/${_id}`, {
        code,
        title,
        en: value,
        status: true,
      });

      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(updateLabelsSuccess(_id, result.data.data[0]));
          toast.success(result.data.message);
          setisOpen(!isOpen);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Update one categories*/
export const updatelanguage = (data) => {
  const { _id, languageName, languageCode, status, isOpen, setisOpen } = data;
  return async function (dispatch) {
    try {
      const result = await instance.post(`/languages/updateLanguage/${_id}`, {
        languageName,
        languageCode,
        status,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(updateLanguageSuccess(_id, data));
          toast.success(result?.data?.message);
          setisOpen(!isOpen);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      console.log(err);
      handleErrors(err);
    }
  };
};

/* Update one countries*/
export const updateCountries = (data) => {
  const {
    _id,
    countryName,
    countryCode,
    currencyCode,
    phoneCode,
    status,
    isOpen,
    setisOpen,
  } = data;
  return async function (dispatch) {
    try {
      const result = await instance.post(`/countries/updateCountry`, {
        countryId: _id,
        countryName,
        countryCode,
        currencyCode,
        phoneCode,
        status,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(updateCountriesSuccess(_id, data));
          dispatch(getcountries());
          toast.success(result?.data?.message);
          setisOpen(!isOpen);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      console.log(err);
      handleErrors(err);
    }
  };
};

/* Get All commission*/
export const getCommission = () => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/commission/getAppCommission`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getCommissionSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Add one Labels*/
export const SetCommission = (amount, note, settogglemenu, togglemenu) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/commission/createAppCommission`, {
        amount,
        note,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(addCommissionsSuccess(result.data.data));
          settogglemenu(!togglemenu);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All plan*/
export const getPlan = () => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/plan/getAllPlan`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getplanSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Add one plan*/
export const SetPlan = (
  title,
  amount,
  duration,
  minutes,
  tage,
  settogglemenu,
  togglemenu
) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/plan/addPlan`, {
        title,
        amount,
        duration,
        minutes,
        tage,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(addplanSuccess(result.data.data));
          settogglemenu(!togglemenu);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Add one plan*/
export const UpdatPlan = (data) => {
  const {
    _id,
    title,
    status,
    amount,
    duration,
    minutes,
    tage,
    isOpen,
    setisOpen,
  } = data;
  return async (dispatch) => {
    try {
      const result = await instance.post(`/plan/updatePlan/${_id}`, {
        title,
        amount,
        duration,
        minutes,
        tage,
        status,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(updateplanSuccess(_id, result.data.data));
          setisOpen(!isOpen);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All USers*/
export const getListerBalance = (listenerId) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/payment/getListnerBalance`, {
        listenerId,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getListerBalanceSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

export const addPayout = (formData) => {
  const {
    transactionId,
    paidAmount,
    PaymentType,
    remark,
    navigate,
    listenerId,
  } = formData;
  return async (dispatch) => {
    try {
      const result = await instance.post(`/payout/addPaymentOfListener`, {
        transactionId,
        paidAmount,
        PaymentType,
        remark,
        currency: "USD",
        listenerId,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(addPayoutSuccess(result.data.data));
          navigate("/payout");
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All Payout*/
export const getPayout = () => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/payout/getListenerPayoutList`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getPayoutSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All DashboardData*/
export const getDashboardData = () => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/dashboard/dashboardData`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getDashboardDataSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All feedback*/
export const getFeedback = () => {
  return async (dispatch) => {
    try {
      const result = await instance.get(`/feedback/getAllFeedback`);
      if (result.status === 200) {
        if (result?.data?.status) {
          // console.log(result.data.data);
          dispatch(getFeedbackSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All VideoAction*/
export const getVideoAction = (
  videoApproved,
  videoDenyReason = " ",
  userId,
  usenavigate
) => {
  // console.log(videoApproved, (videoDenyReason = " "), userId);
  return async (dispatch) => {
    try {
      const result = await instance.post(`/listener/updateBioStatus`, {
        videoDenyReason,
        videoApproved, // "Cancel", "Pending","Approved","UnderReview"
        userId,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          toast.success(result?.data?.message);
          usenavigate("/listener");
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All InhouseListener*/
export const getInhouseListener = (inhouse_listener, userId) => {
  // console.log(inhouse_listener, userId);
  return async (dispatch) => {
    try {
      const result = await instance.post(`/listener/updateInhouseListener`, {
        inhouse_listener,

        userId,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          toast.success(result?.data?.message);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All Notifications*/
export const getNotification = () => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`admin/notfication/getNotification`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getNotificationSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Add one Notifications*/
export const SetNotifications = (
  title,
  body,
  date,
  notifyFor,
  selectedUsers,
  settogglemenu,
  togglemenu
) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/admin/notfication/addNotification`, {
        title,
        body,
        date,
        notifyFor,
        selectedUsers,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(addNotificationsSuccess(result.data.data));
          settogglemenu(!togglemenu);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Get All Banner */
export const getBanner = () => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/admin/banner/getAllBanners`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getBannerSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Add one Banners*/
export const SetBanners = (
  image,
  name,
  title,
  description,
  link,
  settogglemenu,
  togglemenu
) => {
  return async (dispatch) => {
    try {
      const result = await instanceImage.post(`/admin/banner/addBanner`, {
        image,
        name,
        title,
        description,
        link,
      });
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(addBannersSuccess(result?.data.data));
          toast.success(result?.data?.message);
          settogglemenu(!togglemenu);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Update one Banner*/
export const updateBanner = (data) => {
  const {_id,image,status,name,title,description,link,isOpen,setisOpen} = data;
  return async function (dispatch) {
    try {
      const result = await instanceImage.post(`/admin/banner/update/${_id}`, {
        image,title,name,description,link,status});
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(updateBannerSuccess(_id, result.data.data));
          toast.success(result?.data?.message);
          setisOpen(!isOpen);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

/* Delete one Banner*/
export const bannerDelete = (id, settogglemenu, togglemenu) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/admin/banner/delete/${id}`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(deleteBannerSuccess(id));
          settogglemenu(!togglemenu);
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      toast.error(err.response?.data.message);
    }
  };
};


/* Get All Bookings */
export const getBookings = (filters={}) => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/admin/booking/getAllbookings`,filters);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getBookingSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};


/* Get All Quick Bookings */
export const getQuickBookings = () => {
  return async (dispatch) => {
    try {
      const result = await instance.post(`/admin/booking/getAllQuickbookings`);
      if (result.status === 200) {
        if (result?.data?.status) {
          dispatch(getQuickBookingSuccess(result.data.data));
        } else {
          toast.error(result?.data?.message);
        }
      }
    } catch (err) {
      handleErrors(err);
    }
  };
};

